
.main-editor{
    margin-top: 50px;
}

.close.btn{
    float: right;
}

.input-container{
    display: flex;
    margin: 5px;
}

.input-container label{
    width: 30%;
}

.input-container input{
    width: 70%;
}

.save.btn{
    margin-top: 30px;
}

/* .input-container{
    margin-top: 50px;
}

.input-section{
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    margin: 10px;
}

.input-section .label{
    width: 30%;
}

.input-section .input{
    width: 70%;
}

.input .input-field{
    width: 100%;
    border-radius: 0.5em;
} */



