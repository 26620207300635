@import-normalize;
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css');
@import 'fonts.css';
@import 'recipe-list.css';
@import 'header.css';
@import 'recipe-edit.css';
@import 'recipeEditContainer2.css';
@import 'recipeIngredientEdit.css';

/* @import 'button.css'; */


 *,*::before,*::after{
    box-sizing: border-box;
    font-family: Gotham Rounded, sans-serif;
}

body{
    padding: 0;
    margin: 0;
}

#root{
    display: grid;
    /* flex-direction: column; */

}

.card.header.apptitle{
    position: sticky;
    top: 0;
    z-index: 10;
}

.recipe-container{
    width: 50%;
    min-height: 100%;
    /* min-height: 100vh; */
    padding: 30px;
    overflow: auto;
}

.editor{
    position: fixed;
    padding: 30px;
    padding-top: 35px;
    right: 0;
    top: 0;
    /* border: 1px solid grey; */
    width: 50%;
    min-height: 100%;
    max-height: 100%;
    overflow-y: auto;

}

.editor-closed{
    position: fixed;
    right: 0;
    width: 50%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-left: 1px solid hsl(0, 0%, 50%); */
}

.editor-closed #message{
    text-transform: uppercase;
    text-align: center;
    color: hsl(0, 0%, 50%);
}

.empty-recipe-list{
    position: fixed;
    width: 50%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (orientation:portrait){
    #wrapper { display:none; }
    #warning-message { display:block; }
}

@media only screen and (orientation:landscape){
    #warning-message { display:none; }
}

#warning-message{
    border: 1px solid black;
    position: fixed;
    height: 100%;
    width: 100%;
    align-items: center;
    text-align: center;
    line-height: 100vh;
}